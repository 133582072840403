import React from "react";
import SignUpLayer from "../components/SignUpLayer";

const SignUpPage = () => {
  return (
    <>

      {/* SignUpLayer */}
      <SignUpLayer />

    </>
  );
};

export default SignUpPage; 
