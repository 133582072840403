import React from "react";
import SignInLayer from "../components/SignInLayer";

const SignInPage = () => {
  return (
    <>

      {/* SignInLayer */}
      <SignInLayer />

    </>
  );
};

export default SignInPage; 
